/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 *
 * set the global style such that the Gatsby divs are 100% page height.
 */
require('./src/styles/global.css');
