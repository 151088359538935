// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-gallery-page-gallery-page-template-js": () => import("./../../../src/components/GalleryPage/GalleryPageTemplate.js" /* webpackChunkName: "component---src-components-gallery-page-gallery-page-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prizes-js": () => import("./../../../src/pages/prizes.js" /* webpackChunkName: "component---src-pages-prizes-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-submissions-js": () => import("./../../../src/pages/submissions.js" /* webpackChunkName: "component---src-pages-submissions-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

